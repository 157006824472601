.avatar {
  margin: 0;
}

.button {
  margin: 5px !important;
}

.theme-option {
  display: none;
}
.app-header {
  min-height: 68px;
}
.topSellingProducts {
  padding: 0;
}

.badge {
  font-size: 13px;
}

a:hover {
  text-decoration: none;
}

.editProfile {
  padding-bottom: 10px;
}

.customButton {
  padding: 15px;
  text-align: right;
}

.signinContainer {
  text-align: -webkit-center;
  margin: auto;
  display: block
}

.signinCard {
  width: auto;
}

.m-2 {
  float: left;
}

@media only screen and (min-width:320px) and (min-width:480px) and (min-width:600px) and (min-width:801px) {
  .signinCard {
    width: 50%;
  }
}

.verifyTokenContainer {
  text-align: -webkit-center;
  margin: auto;
  display: block
}

.verifyTokenCard {
  width: auto;
}

@media only screen and (min-width:320px) and (min-width:480px) and (min-width:600px) and (min-width:801px) {
  .verifyTokenCard {
    width: 50%;
  }
}

.forgotPasswordContainer {
  text-align: -webkit-center;
  margin: auto;
  display: block
}

.forgotPasswordCard {
  width: auto;
}

@media only screen and (min-width:320px) and (min-width:480px) and (min-width:600px) and (min-width:801px) {
  .forgotPasswordCard {
    width: 50%;
  }
}

.button {
  padding: 0 12px !important;
}

.user-complaince-check {
  color: white !important;
  box-shadow: unset !important;
  width: 25px !important;
  height: 25px !important;
  background-color: #4caf50 !important;
  min-height: auto !important;
}

.user-complaince-gst {
  color: white !important;
  box-shadow: unset !important;
  width: 25px !important;
  height: 25px !important;
  background-color: #072791 !important;
  min-height: auto !important;
}

.user-name {
  margin-top: 8px;
}

.complaince-button {
  margin-left: 10px !important;
  background-color: #4caf50 !important;
}

.complaince-button-gst {
  margin-left: 10px !important;
  background-color: #072791 !important;
  color: white;
}

.complaince-datePicker {
  margin-left: 30px !important;
}

.filterButton {
  margin-right: 10px !important;
}

.orderListButton {
  padding: 0 12px !important;
}

.rating {
  font-size: 20px;
}

.ratingSpan {
  color: #000000 !important;
  font-size: 13px !important;
}

.ratingIcon {
  text-align: center;
  font-size: 20px !important;
}

.ratingDanger {
  color: #f44336 !important;
  font-size: 30px;
}

.ratingSuccess {
  color: #4caf50 !important;
  font-size: 30px !important;
}

.trackOrderID {
  font: bold;
  float: left;
}

.trackOrderDate {
  font: bold;
  float: right;
}

.timeline-section {
  padding-top: 15px
}

.user-detail-name {
  padding-left: 10px;
}

.nav-text {
  font-size: 17px;
  /* color : #252525; */
}

ul.sub-menu .nav-text {
  font-size: 16px;
}

.indigo ul.nav-menu li button {
  color: #252525 !important;
}

.indigo ul.nav-menu li a {
  color: #252525 !important;
}

.user-detail h4 {
  font-size: 17px !important;
  color: #252525 !important;
}

.app-logo img {
  height: 90px;
}

.medilogo {
  object-fit: contain;
  width: 150px;
  height: auto;
  bottom: 0;
}

.medilogo-container {
  padding: 10px;
}

.slick-slide-item-custom{
  width: 100% !important;
  height: 140px;
}

.brand-logo {
  position: relative;
  padding-bottom: 68%;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.brand-logo .brand-logo-inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}
.signin {
  margin-left: 0 !important;
  background-color: #072791 !important;
}

.groupButton {
  border: 1px solid #3f51b580 !important;
  border-radius: 0 !important;
}

#filterReset-button {
  display: none !important;
}

.addGroupButton {
  align-self: flex-end !important;
}

.addStaffAvatar {
  text-align: -webkit-center;
  padding: 23px;
}

.dashboardInvoice {
  justify-content: flex-end !important;
}

.vacationMode {
  padding: 7px 0px 8px 20px !important;
  align-items: center !important;
  color: #252522 !important;
  font-size: 14px !important;
  min-width: inherit !important;
}

.vacationToggle {
  margin-left: 61px !important;
}

.vacationDatePicker {
  width: 100% !important;
}

.vacationDate {
  color: #072791 !important;
}

.indigo ul.nav-menu>li.open>a {
  color: #ffffff !important;
  background-color: #072791 !important;
  border-top-right-radius: 1.875rem !important;
  border-bottom-right-radius: 1.875rem !important;
  margin-right: 20px !important;
}

.indigo ul.nav-menu li.open button {
  border-top-right-radius: 1.875rem !important;
  border-bottom-right-radius: 1.875rem !important;
  margin-right: 20px !important;
  color: #252525 !important;
}

.tab-custom {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  line-height: 1.6 !important;
  letter-spacing: 0.0075em !important;
  color: #252525 !important;
}

.plus-icon-custom {
  align-items: center;
  justify-content: center;
  text-align: center;
  justify-items: center;
  justify-self: center;
  display: flex;
}

.user-avatar-custom{
  height: 90px !important;
  width: 90px !important;
  border-radius: 0% !important;
}

.recharts-default-tooltip{
  color: #000000;
}