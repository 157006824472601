.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}

.carousel-control-next {
  justify-content: flex-end;
  width: 25%;
}

.carousel-control-prev {
  justify-content: flex-start;
  width: 25%;
}

.product-item {
  box-shadow: -5px 5px 4px 0 rgba(0, 0, 0, 0.2) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.backgroundCover {
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.btnPrimary {
  background-color: #072791 !important;
  color: white !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: #072791 ;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #072791 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #072791 !important;
}

@media only screen and (min-width:100px) {
  .app-logoB img {
    width: 100px;
    height: auto;
  }
}

@media only screen and (min-width:693px) {
  .app-logoB img {
    width: 144px;
    height: auto;
  }
}

.app-header-horizontal .app-toolbar .search-bar {
  margin-left: 0px !important;
  max-width: 100% !important;
  width: 100% !important;
}

.iconAvtarColor {
  width: 22px !important;
  height: 22px !important;
  margin-right: -1px !important;
  margin-left: -3px !important;
}

.slick-track {
  margin-bottom: 9px !important;
}

.app-notification-menu .icon {
  font-size: 20px !important;
}

.dropDown {
  padding-left: 24% !important;
  display: flex;
  align-self: center;
}

.cartIcon {
  text-align: end !important;
  display: flex;
  align-self: center;
}

@media only screen and (min-width:801px) {
  .paddingZero {
    padding: 0px !important;
  }
}

.shopping-cart {
  font-size: large;
}

.intranetCard {
  border-bottom: 1px solid #daddf1 !important;
  margin-bottom: 10px;
  /* padding: 10px 5px 30px !important; */
  padding: 10px 25px 30px !important;
}

.intranetCardViewSeller .carousel-control-next-icon {
  width: 35px !important;
  height: 25px !important;
  border-radius: 50% !important;
  border-bottom: 1px solid #ffffff !important;
}

.intranetCardViewSeller .carousel-control-prev-icon {
  width: 35px !important;
  height: 25px !important;
  border-radius: 50% !important;
  border-bottom: 1px solid #ffffff !important;
}

.intranetCardViewSeller {
  border-bottom: 1px solid #daddf1 !important;
  margin-bottom: 10px !important;
}

.intranetCardSellerDashboard .carousel-control-next-icon {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  border-bottom: 1px solid #ffffff !important;
}

.intranetCardSellerDashboard .carousel-control-prev-icon {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  border-bottom: 1px solid #ffffff !important;
}

.intranetCardSellerDashboard {
  border-bottom: 1px solid #daddf1 !important;
  margin-bottom: 10px !important;
  padding-bottom: 16px !important;
}

.carousel-control-next-icon {
  background-image: url('assets/images/next.png') !important;
}

.carousel-control-prev-icon {
  background-image: url('assets/images/back.png') !important;
}

.dashboardCategory {
  padding: 10px !important;
}

.intranetCard .card-title {
  font-size: 20px !important;
  margin-bottom: 10px !important;
}

.card-body-custom {
  padding-left: 15px;
}

.dashboardCategory {
  padding: 10px !important;
}

.card-titleIntranet {
  font-size: 20px !important;
  margin-bottom: 10px !important;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.375rem - 1px) calc(.375rem - 1px);
}

.intranetFooter {
  border-top: 1px solid #daddf1 !important;
  margin-bottom: 10px !important;
  padding: 10px 10px 30px !important;
  /* padding: 10px 30px 30px !important; */
  margin: 32px -30px -51px !important;
  /* border-radius: 0.375rem 0.375rem 0 0 !important; */
  position: relative !important;
  white-space: nowrap !important;
}

/* .categoryCard {
  width: -100px !important;
} */

@media only screen and (min-width:320px) and (min-width:480px) and (min-width:600px) {
  .categoryCard {
    margin-top: -100px !important;
  }
}

.originalPrice {
  color: #6c757d !important;
  /* #B12704!important; */
  text-decoration: line-through;
  font-weight: bold;
}

.font {
  font-size: 12px !important;
  line-height: 19px !important;
}

.priceColor {
  color: #4caf50 !important;
  font-size: 25px !important;
  line-height: 19px !important;
  /* font-weight: bold; */
}

.priceColorInventory {
  color: #4caf50 !important;
  font-size: 17px !important;
  line-height: 19px !important;
}


.discountPrice {
  color: #555 !important;
  font-size: 13px !important;
  line-height: 1.255 !important;
}

@media only screen and (max-width:480px) {
  .itemImage {
    height: 285px !important;
  }
}

.buyerEditProfile {
  justify-content: flex-end !important;
  display: flex !important;
}

@media only screen and (max-width:480px) {
  .profileTab {
    font-size: .75rem !important;
  }

  .cartIcon {
    justify-content: flex-end !important;
    display: flex;
    align-self: center;
  }
}

.ptrPriceFont {
  padding-left: 6px;
  /* font-weight: bold; */
  color: #4caf50 !important;
  /* padding-left: 5px; */
}

.buyerRedirectNavlink {
  color: #6c757d !important;
}

.filterBody:hover {
  text-decoration: none !important;
  background-color: #ffffff !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 3.5px 4px !important;
}

a {
  color: #072791;
  text-decoration: none;
  background-color: initial;
}

.MuiTableCell-root {
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.MuiTableCell-head {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
}

.horizontaly-overflow {
  height: 100%;
  overflow: auto;
  max-height: 499px;
  overflow-x: auto;
}

.form-label {
  margin-bottom: 0px !important;
}

[class*="-MuiSelect-select-MuiInputBase-input-MuiInput-input"] {
  padding: 0px !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.MuiTableCell-head {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
}

.bg-pink{
  background-color: #c528c7;
}