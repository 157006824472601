.buyerDetailsHeader {
  padding: 22px 26px !important;
  z-index: 2 !important;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.search-input {
  font-size: 18px;
  height: 44px;
  padding-left: 40px;
  /* Adjust the left padding to make room for the icon */
}

.search-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  /* Adjust the left position of the icon */
  top: 50%;
  /* Center the icon vertically */
  transform: translateY(-50%);
  background-color: transparent;
  font-size:20px;
  color : rgba(0, 0, 0, 0.54);
}

.search-icon {
  color : rgba(0, 0, 0, 0.54);
}

.title {
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin-bottom: 25px;
}

.header {
  margin-top: 25px;
  margin-bottom: 25px;
  /* background-color: white; */
}

.Row-header{
display:flex;
}

@media (max-width: 600px) {
  .Row-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .Row-header > div {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .Row-header {
  display: block; /* Columns take 100% width on smaller screens */
    /* Additional styles for columns on smaller screens */
  }
}



/* Media query for screens larger than 992px (typical laptops and desktops) */
@media (min-width: 992px) {
  .Row-header {
    width: 33.33%; /* Columns take 33.33% width on large screens */
    /* Additional styles for columns on large screens */
  }
}

/* .css-jgls56-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #3f51Bf !important;
}
.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selecte{
  background-color: #3f51Bf !important;
} */

.custom-datepicker div input{
  padding:11px;
}
.MuiPickersDay-dayWithMargin.MuiPickersDay-today.Mui-selected {
  background-color: #3f51Bf !important;
}
.MuiPickersDay-dayWithMargin.Mui-selected {
  background-color: #3f51Bf !important;
}

.search-input::placeholder {
  font-size: 0.875rem; /* Adjust the font size as needed */
}